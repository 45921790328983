@import url('https://fonts.googleapis.com/css2?family=Amatic+SC&family=Annie+Use+Your+Telescope&family=Bad+Script&family=Cookie&family=Waiting+for+the+Sunrise&family=Lato&family=Handlee&family=Delius&display=swap');
/*
'Amatic SC', cursive;
'Annie Use Your Telescope', cursive;
'Bad Script', cursive;
'Cookie', cursive;
'Waiting for the Sunrise', cursive;
*/

:root {
    --colour-background-primary: #d5a6e9;
    --colour-background-secondary: #68c5dd;
    --colour-background-tertiary: #91f7cd;
    --colour-dark: #424242;
    --colour-primary: #D96690;
    --colour-secondary: #96D2D9;
    --colour-green: #BAD9C8;
    --font-logo: 'Annie Use Your Telescope', cursive;
    --font-primary: 'Delius', cursive;
    --font-secondary: 'Amatic SC', cursive;
    --font-normal: 'Lato', sans-serif;
    --nav-bar-height: 4em
}