.projects {
    background: linear-gradient(to bottom, var(--colour-background-secondary), var(--colour-background-tertiary));
}
.projects .content {
    margin: auto;
    width: 80vw;
}
.projects .content .project {
    padding: 60px 0;
}
.projects .content .project img {
    display: inline-block;
    width: 40vw;
    border-radius: 5px;
}
.projects .content .project section {
    display: inline-block;
    width: 30vw;
    margin: 0 5vw;
    vertical-align: top;
    font-size: 1.2em;
}