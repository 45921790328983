.divider {
    height: 160px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 40px rgba(0, 0, 0, 0.5);
}
.divider h1 {
    margin: 0;
    text-align: center;
    line-height: 140px;
    font-size: 2.4em;
    color: white;
}