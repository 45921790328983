.intro {
    padding-top: calc(var(--nav-bar-height) + 4em);
    background-color: transparent;
    padding-bottom: 40px;
    background: linear-gradient(to bottom, 
                                var(--colour-background-primary), 
                                var(--colour-background-secondary));
}
.intro:after {
    content: "";
    display: table;
    clear: both;
}
.intro img {
    display: inline-block;
    width: 40vw;
    max-width: 800px;
    margin-top: 2em;
}
.intro .content {
    display: inline-block;
    vertical-align: top;
    margin: 0 8vw;
    font-size: 1.4em;
    color: #2c2c2c;
    width: 40vw;
}
.intro .content p {
    font-size: 1em;
}
.intro .buttons {
    text-align: center;
    margin: auto;
    margin-bottom: 10px;
}
.intro .buttons a {
    background-color: var(--colour-primary);
    width: 120px;
    padding: 10px;
    margin: 30px;
    font-size: 1.2em;
    border-radius: 4px;
    line-height: 2em;
    color: white;
    text-decoration: none;
}
.intro .buttons a:hover {
    background-color: var(--colour-secondary);
    color: black;
    font-weight: bold;
}