.nav {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    padding: 0.5em 0;
    height: var(--nav-bar-height);
    width: 100vw;
    backdrop-filter: blur(10px);
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
}
.nav img {
    float: left;
    width: 4em;
}
.nav h1 {
    display: inline;
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 10px;
    color: var(--colour-primary);
    font-size: 3em;
    font-family: var(--font-logo);
    font-weight: normal;
}
.nav ul {
    list-style: none;
    float: right;
    margin: 0;
    border: 0;
    line-height: var(--nav-bar-height);
    z-index: 1;
}
.nav ul li {
    display: inline-block;
    padding-right: 40px;
    font-size: 1.6em;
    vertical-align: middle;
}
.nav img {
    float: left;
    width: 4em;
}

.nav a {
    text-decoration: none;
    color: var(--colour-dark);
}
.nav a:hover {
    color: var(--colour-primary);
}
.nav a:visited {
    color: var(--colour-dark);
}
.nav a:visited:hover {
    color: var(--colour-primary);
}

.nav .social {
    float: right;
    height: 100%;
    margin-top: 1em;
    margin-right: 30px;
}
.nav .social a {
    line-height: 4em;
}
.nav .social img {
    width: 30px;
    margin-right: 10px;
    border-radius: 3px;
}