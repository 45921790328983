@import url(https://fonts.googleapis.com/css2?family=Amatic+SC&family=Annie+Use+Your+Telescope&family=Bad+Script&family=Cookie&family=Waiting+for+the+Sunrise&family=Lato&family=Handlee&family=Delius&display=swap);
body {
  font-family: 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  overflow-x: hidden;
}
/*
'Amatic SC', cursive;
'Annie Use Your Telescope', cursive;
'Bad Script', cursive;
'Cookie', cursive;
'Waiting for the Sunrise', cursive;
*/

:root {
    --colour-background-primary: #d5a6e9;
    --colour-background-secondary: #68c5dd;
    --colour-background-tertiary: #91f7cd;
    --colour-dark: #424242;
    --colour-primary: #D96690;
    --colour-secondary: #96D2D9;
    --colour-green: #BAD9C8;
    --font-logo: 'Annie Use Your Telescope', cursive;
    --font-primary: 'Delius', cursive;
    --font-secondary: 'Amatic SC', cursive;
    --font-normal: 'Lato', sans-serif;
    --nav-bar-height: 4em
}
body {
    margin: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    min-height: 200vh;
    font-family: var(--font-primary);
}
.intro {
    padding-top: calc(var(--nav-bar-height) + 4em);
    background-color: transparent;
    padding-bottom: 40px;
    background: linear-gradient(to bottom, 
                                var(--colour-background-primary), 
                                var(--colour-background-secondary));
}
.intro:after {
    content: "";
    display: table;
    clear: both;
}
.intro img {
    display: inline-block;
    width: 40vw;
    max-width: 800px;
    margin-top: 2em;
}
.intro .content {
    display: inline-block;
    vertical-align: top;
    margin: 0 8vw;
    font-size: 1.4em;
    color: #2c2c2c;
    width: 40vw;
}
.intro .content p {
    font-size: 1em;
}
.intro .buttons {
    text-align: center;
    margin: auto;
    margin-bottom: 10px;
}
.intro .buttons a {
    background-color: var(--colour-primary);
    width: 120px;
    padding: 10px;
    margin: 30px;
    font-size: 1.2em;
    border-radius: 4px;
    line-height: 2em;
    color: white;
    text-decoration: none;
}
.intro .buttons a:hover {
    background-color: var(--colour-secondary);
    color: black;
    font-weight: bold;
}
.divider {
    height: 160px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 40px rgba(0, 0, 0, 0.5);
}
.divider h1 {
    margin: 0;
    text-align: center;
    line-height: 140px;
    font-size: 2.4em;
    color: white;
}
.projects {
    background: linear-gradient(to bottom, var(--colour-background-secondary), var(--colour-background-tertiary));
}
.projects .content {
    margin: auto;
    width: 80vw;
}
.projects .content .project {
    padding: 60px 0;
}
.projects .content .project img {
    display: inline-block;
    width: 40vw;
    border-radius: 5px;
}
.projects .content .project section {
    display: inline-block;
    width: 30vw;
    margin: 0 5vw;
    vertical-align: top;
    font-size: 1.2em;
}
.contact {
    background: linear-gradient(to bottom, var(--colour-background-tertiary), var(--colour-background-primary));
    text-align: center;
    padding-top: 40px;
}
.contact .content .links {
    display: flex;
    justify-content: center;
}
.contact .content a {
    text-align: center;
    margin: 20px;
    text-decoration: none;
    border-radius: 10px;
}
.contact .content p {
    margin: 0;
    font-size: 1.2em;
    color: black;
}
.contact .content a img {
    width: 60px;
}
.nav {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    padding: 0.5em 0;
    height: var(--nav-bar-height);
    width: 100vw;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
}
.nav img {
    float: left;
    width: 4em;
}
.nav h1 {
    display: inline;
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 10px;
    color: var(--colour-primary);
    font-size: 3em;
    font-family: var(--font-logo);
    font-weight: normal;
}
.nav ul {
    list-style: none;
    float: right;
    margin: 0;
    border: 0;
    line-height: var(--nav-bar-height);
    z-index: 1;
}
.nav ul li {
    display: inline-block;
    padding-right: 40px;
    font-size: 1.6em;
    vertical-align: middle;
}
.nav img {
    float: left;
    width: 4em;
}

.nav a {
    text-decoration: none;
    color: var(--colour-dark);
}
.nav a:hover {
    color: var(--colour-primary);
}
.nav a:visited {
    color: var(--colour-dark);
}
.nav a:visited:hover {
    color: var(--colour-primary);
}

.nav .social {
    float: right;
    height: 100%;
    margin-top: 1em;
    margin-right: 30px;
}
.nav .social a {
    line-height: 4em;
}
.nav .social img {
    width: 30px;
    margin-right: 10px;
    border-radius: 3px;
}
